import React from "react";

const ProtectedPage = () => {
  return (
    <div>
      <h1>This page is hyper protected!</h1>
    </div>
  );
};

export default ProtectedPage;
